import Layout from "./common/layout"
import SocialCommerce from "./channels/SocialCommerce/SocialCommerce"
import Head from "./common/Head"
import { useFormatter } from "helpers/i18n"

const ChannelsSocialCommerce = () => {
  const { currentLocale } = useFormatter()

  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "Leading Social Commerce platforms and solutions"
            : "Giải pháp và nền tảng Social Commerce hàng đầu khu vực"
        }
        description={
          currentLocale === "en"
            ? "With Social Commerce solutions, OnPoint helps you to expand your social media presence, increase your target customer and revenue streams."
            : "Với Social Commerce, OnPoint giúp doanh nghiệp mở rộng sự hiện diện trên mạng xã hội, gia tăng khách hàng mục tiêu và đa dạng hoá nguồn doanh thu."
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/channel-2-meta.png"
        }
      />
      <Layout>
        <SocialCommerce />
      </Layout>
    </>
  )
}

export default ChannelsSocialCommerce
